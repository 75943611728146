.flashMessage {
  $state-list: info danger warning success;
  $state-color-list: $flashmessage_info $flashmessage_danger $flashmessage_warning $flashmessage_success;

  border: 1px solid;
  border-radius: 5px;
  padding: 7.5px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: 13px;
  font-size: 14px;

  &-icon {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 15px;
    height: 15px;
    fill: currentColor;
    margin-right: 5px;
  }

  &.alert-dismissible {
    .close {
      color: inherit;
      position: absolute;
      right: 16px;
      top: 6px;
    }
  }

  @for $i from 1 through length($state-list) {
    &--#{nth($state-list, $i)} {
      color: nth($state-color-list, $i);
      border-color: nth($state-color-list, $i);
      background: lighten(nth($state-color-list, $i), 50%);
    }
  }
}

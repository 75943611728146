.fileDownloadDetail {
  color: $c_Mako;
  cursor: pointer;
  padding: 16px 0 16px 0;
  border-bottom: 1px solid $c_Iron;
  display: block;

  &:first-child {
    margin-top: 14px;
  }

  p {
    font-weight: 900;
    padding: 0;
    margin: 0;
    font-size: 9px;
  }

  .fileicon {
    margin-top: 25px !important;
  }

  .table {
    margin-top: 5px;

    tr td,
    .table tr th {
      padding: 5px;
    }
  }

  span {
    margin: 0;

    &.link {
      color: $link-color;

      svg {
        fill: $link-color;
      }
      &:hover,
      &:active,
      &:focus {
        color: $link-hover-color;
        svg {
          fill: $link-hover-color;
        }
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: $c_Mako;
  }
}

.grid-stack {
  .panel {
    margin: 0;
    box-shadow: none;

    [class^='img-wrapper'],
    [class*=' img-wrapper'] {
      margin-right: 20px;

      img {
        margin: 0;
      }
    }
  }

  &-item-content {
    background-color: $c_White;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  .donut {
    .percent {
      font-size: 16px;
    }

    circle {
      fill: none;
    }

    &__stop1 {
      stop-color: $c_BahamaBlue_w15;
    }

    &__stop2 {
      stop-color: $c_BahamaBlue_b15;
    }

    .circle-back {
      stroke: $c_Iron;
      stroke-width: 5px;
    }

    .circle-front {
      stroke: url(#quotaDonut__gradient);
      stroke-width: 5px;

      /* 2 * Math.PI * radius + 1  */
      stroke-dasharray: 268.0353755551324px;
      stroke-dashoffset: 268.0353755551324px;
      transition: stroke-dashoffset 0.5s ease;
    }
  }

  .filesharing {
    &__pie {
      float: left;
    }

    &__info {
      float: left;
      margin-left: 30px;
    }

    h4 {
      margin-bottom: 14px;
    }
  }
}

.header-simple {
  background-color: $c_White;

  &::before {
    display: table;
    background-position: 0 -100px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
}

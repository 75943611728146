#main-wrapper {
  transition: all, 0.2s, ease-in-out;
  overflow: hidden;

  @if $header_supergraphic == 'none' {
  } @else {
    &::before {
      content: '';
      background-image: url($header_supergraphic);
      background-size: cover;
      width: 100%;
      position: fixed;
      top: 0;
      height: 6px;
      z-index: 99999;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }
}

.main-content-wrapper {
  position: relative;

  & #main-content {
    padding: 15px 30px 30px 30px;
    width: 100%;
    min-height: calc(100vh - #{$headerHeight});

    & .h1 {
      margin: 0;
      padding: 0 10px 40px 10px;
      float: left;
      line-height: 10px;
      font-weight: 300;
      font-size: 24px;
    }
  }
}

.main-content-toggle-left {
  margin-left: 0;
}

.main-content-toggle-right {
  margin-right: 240px;
}

@media only screen and (max-width: 660px) {
  .main-content-toggle-left {
    margin-left: 660px;
  }
}

@media only screen and (max-width: 479px) {
  /* Main Content */
  #main-content .h1 {
    font-size: 22px;
  }
}

$bmsgWidth: 260px;
$bmsgHeight: 180px;

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

.backdrop {
  &__message {
    position: fixed;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    margin-top: -#{$bmsgWidth / 2};
    margin-left: -#{$bmsgHeight / 2};
    width: $bmsgWidth;
    height: $bmsgHeight;
    background-color: $c_White;
    padding: 20px;

    h4,
    p {
      text-align: center;
    }

    svg {
      overflow: visible;
      width: 40px;
      height: 40px;
      animation: 2s linear infinite svg-animation;
      margin: auto;
    }

    circle {
      animation: 1.4s ease-in-out infinite both circle-animation;
      display: block;
      fill: transparent;
      stroke: #2f3d4c;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      transform-origin: 50% 50%;
    }
  }
}

.login-header {
  display: flex;
  width: 100%;
  min-height: 80px;
  align-items: center;

  nav {
    margin-left: auto;
    font-size: 16px;

    > ul {
      display: flex;
      list-style: none;
      padding: 0;

      > li {
        padding: 0 10px;

        a {
          color: $c_Black;
        }
      }
    }
  }

  .selectCountry {
    margin-left: 5px;
    padding-left: 15px;
    border-left: 1px solid $c_Black;

    .icon-arrow-right {
      fill: $c_White;
      width: 10px;
      height: 10px;
      transform: rotate(90deg);
      margin-left: 5px;
    }
  }
}

// Variables, Mixins
@import 'includes/mixins';
@import './colors';
@import 'variables';
@import './colormap';
@import 'includes/bootstrap-custom';

@import 'global';

// Include Components
// @import '../components/**/*.scss';

// Include only selected components for mediapool!
@import 'mediapool';

// Theme - Map Colors to css selectors
.theme-boschtt {
  @import 'theme';

  .navbar-left {
    display: flex;
    align-items: center;
  }

  .login-logo {
    width: 106px;
  }

  .navbar-left__logo {
    width: 106px;
    object-fit: contain;
  }

  .btn,
  .flashMessage,
  .form-control,
  .brands__image img,
  .qtip-bootstrap,
  .productstage .content .expand,
  .solid .modal-content,
  .customRadioCheckbox .checkbox-custom + .checkbox-custom-label::before,
  .customRadioCheckbox .checkbox-custom + * + .checkbox-custom-label::before,
  .customRadioCheckbox .radio-custom + .radio-custom-label::before,
  .dataTables_length select,
  .manual .wrapper,
  .manual .outterwrapper {
    border-radius: 0 !important;
  }

  .btn-secondary {
    background: unset;

    &:hover {
      background: unset;
    }
  }

  .sidebar-left {
    .nav {
      a {
        border-top: unset;
      }
    }
  }

  #header {
    .inner {
      background: $c_White;
    }
  }

  .pageheader {
    background: $c_White;
  }

  .navbar-right li {
    &:not(:last-of-type) {
      border-left: none;
    }
  }
}

.uploadDrop {
  display: flex;

  .dz-file-preview {
    background-color: $c_White;
  }

  .dz-preview {
    margin: 0;
    padding: 8px 16px;
    border-radius: 4px;

    &.dz-processing .dz-progress {
      opacity: 1;

      transition: all 0.2s linear;
    }

    &.dz-complete .dz-progress {
      opacity: 0;
      transition: opacity 0.4s ease-in;
    }

    &:not(.dz-processing) {
      .dz-progress {
        animation: pulse 6s ease infinite;
      }
    }

    .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 10px;
      left: 50%;
      bottom: 10%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);
      transform: scale(1);
      border-radius: 8px;
      overflow: hidden;

      .dz-upload {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 300ms ease-in-out;
      }
    }
  }

  &__item {
    display: flex;
  }
}

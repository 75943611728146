:root {
  --fsMD: 14px;
}

html {
  font-size: var(--fsMD);
}

body {
  position: relative;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
.h1 {
  font-size: 28px;
  font-weight: normal;
}

h2,
.h2 {
  font-size: 19px;
}

h3,
.h3 {
  font-size: 17px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

p {
  font-size: 14px;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

img {
  max-width: 100%;
}

label {
  font-weight: normal;
}

.revision {
  margin: 10px 30px;
  color: $c_RollingStone;
}

::selection {
  color: $c_White;
  background: $c_BahamaBlue_w15;
}

.polyCheckbox {
  display: flex;
  background-color: $c_Seashell;
  padding: 10px 15px 10px 35px;
  line-height: 22px;
  cursor: pointer;
  align-items: center;

  &:hover,
  .polyCheckbox__state:checked + & {
    color: $c_White;
    background-color: $c_BahamaBlue;
  }

  &--big {
    line-height: 58px;
    background-color: $c_White;
    padding: 10px 15px 10px 10px;

    &:hover,
    .polyCheckbox__state:checked + & {
      background-color: $c_BahamaBlue_w15;
    }
  }

  &--check {
    &:hover,
    .polyCheckbox__state:checked + & {
      background-color: $c_BahamaBlue;
    }

    .icon {
      opacity: 0;
      width: 15px;
      height: 15px;
      margin-left: auto;
      fill: $c_White;
      transition: opacity, 1s, ease;
    }

    .polyCheckbox__state:checked + & .icon {
      opacity: 1;
    }
  }

  &--file {
    padding: 5px 16px 5px 35px;
    margin-bottom: 1px;
    background-color: $c_White;

    &:hover,
    .polyCheckbox__state:checked + & {
      color: inherit;
      background-color: $c_Lima_w60;
      border-left: 15px solid $c_Lima;
      padding-left: 20px;
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  &--white {
    background-color: $c_White;
  }
}

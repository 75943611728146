.autoCompleteInput {
  @keyframes 'blink' {
    0% {
      border-color: $c_Iron;
    }

    100% {
      border-color: $c_Monza;
    }
  }

  .tokenfield {
    height: auto;
    min-height: 26px;
    padding: 5px;

    &.focus {
      border-color: $c_BahamaBlue_w15;
      outline: 0;
    }

    .twitter-typeahead {
      min-width: 50%;
    }

    .token {
      box-sizing: border-box;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid $c_LimedSpruce;
      background-color: $c_LimedSpruce;
      color: $c_White;
      white-space: nowrap;
      margin: 0 10px 0 0;
      height: 26px;
      padding-left: 5px;
      vertical-align: top;
      cursor: default;

      &:hover {
        border-color: $c_LimedSpruce_w15;
      }

      &.active {
        border-color: $c_BahamaBlue_w15;
      }

      &.duplicate {
        border-color: $c_Monza_w85;
        animation-name: blink;
        animation-duration: 0.1s;
        animation-direction: normal;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      }

      &.invalid {
        background: none;
        border: 1px solid transparent;
        border-radius: 0;
        border-bottom: 1px dotted $c_Monza;

        &.active {
          background: $c_FrenchGray;
          border: 1px solid $c_FrenchGray;
          border-radius: 3px;
        }
      }

      .token-label {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 26px;
        vertical-align: middle;
      }

      .close {
        font-family: Arial, sans-serif;
        display: inline-block;
        font-size: 12px;
        line-height: 26px;
        margin-left: 5px;
        float: none;
        height: 100%;
        color: $c_White;
        vertical-align: middle;
        padding-right: 5px;
        opacity: 0.6;
        filter: alpha(opacity=60);
      }
    }

    .token-input {
      width: 100% !important;
      background: none;
      min-width: 60px;
      border: 0;
      height: 26px;
      padding: 0;
      margin: 0;
      box-shadow: none;

      &:focus {
        border-color: transparent;
        outline: 0;
        box-shadow: none;
      }
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $c_Iron;

      .token-input {
        cursor: not-allowed;
        width: 100% !important;
      }

      .token:hover {
        cursor: not-allowed;
        border-color: $c_Iron;

        .close {
          cursor: not-allowed;
          opacity: 0.6;
          filter: alpha(opacity=60);
        }
      }
    }

    &.input-sm {
      min-height: 30px;
      padding-bottom: 0;

      .token {
        height: 20px;
        margin-bottom: 4px;
      }

      .token-input {
        height: 18px;
        margin-bottom: 5px;
        width: 100% !important;
      }
    }

    &.input-lg {
      min-height: 45px;
      padding-bottom: 4px;

      .token {
        height: 25px;

        .close {
          line-height: 13px;
        }
      }

      .token-label {
        line-height: 23px;
      }

      .token-input {
        height: 23px;
        line-height: 23px;
        margin-bottom: 6px;
        vertical-align: top;
        width: 100% !important;
      }
    }

    &.rtl {
      direction: rtl;
      text-align: right;

      .token {
        margin: -1px 0 5px 5px;

        .token-label {
          padding-left: 0;
          padding-right: 4px;
        }
      }
    }
  }

  .input-group-sm {
    .tokenfield {
      min-height: 30px;
      padding-bottom: 0;
    }

    .token {
      height: 20px;
      margin-bottom: 4px;
    }

    .token-input {
      height: 18px;
      margin-bottom: 5px;
      width: 100% !important;
    }
  }

  .input-group-lg {
    .tokenfield {
      min-height: 45px;
      padding-bottom: 4px;
    }

    .token {
      height: 25px;

      .close {
        line-height: 13px;
      }
    }

    .token-label {
      line-height: 23px;
    }

    .token-input {
      height: 23px;
      line-height: 23px;
      margin-bottom: 6px;
      vertical-align: top;
      width: 100% !important;
    }
  }

  .has-warning .tokenfield.focus {
    border-color: $c_SelectYellow;
    box-shadow: inset 0 1px 1px $c_Black, 0 0 6px $c_SelectYellow_w85;
  }

  .has-error .tokenfield.focus {
    border-color: $c_Monza;
    box-shadow: inset 0 1px 1px $c_Black, 0 0 6px $c_Monza_w85;
  }

  .has-success .tokenfield.focus {
    border-color: $c_Lima;
    box-shadow: inset 0 1px 1px $c_Black, 0 0 6px $c_Lima_w60;
  }

  &.scrollable-dropdown-menu {
    width: 100%;

    .tt-menu {
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
    }
  }

  .typeahead {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0 0;
    list-style: none;
    background-color: $c_White;
    border: 1px solid $c_FrenchGray;

    li {
      line-height: 20px;
    }

    a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 20px;
      color: $c_FrenchGray;
      white-space: nowrap;
      text-decoration: none;
    }

    .active > a {
      color: $c_White;
      text-decoration: none;
      background-color: $c_BahamaBlue;
      outline: 0;
    }

    &.hidden {
      display: none;
    }
  }

  .tt-menu {
    background: $c_White;
    border: 1px solid $c_Mako;
    color: $c_RollingStone;
    padding: 10px;
    margin-top: 10px;

    .tt-suggestion {
      padding: 5px;

      img {
        max-height: 150px;
      }

      &:hover {
        background: $c_Seashell;
        cursor: pointer;
      }

      p {
        line-height: 14px;
        margin: 0 0 10px;
      }

      .headline {
        font-weight: 700;
        color: $c_Mako;
      }
    }

    .warning-color {
      line-height: 14px;
      margin: 0;
    }

    .tt-suggestion--width {
      min-width: 200px;
    }
  }
}

@keyframes blink_outline {
  50% {
    outline: 2px dashed $c_BahamaBlue_b15;
  }
}

.editableItem {
  &:focus {
    outline: 2px dotted $c_BahamaBlue_w15;
    animation: blink_outline 0.5s step-end infinite alternate;
  }
}

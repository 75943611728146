.form-group {
  > label {
    font-size: 11px;
    padding-left: 13px;
  }
}

.form-control {
  border: 1px solid $c_FrenchGray;
  border-radius: 2px;
  box-shadow: none;
  padding: 8px 12px 9px 12px;
}

.form-control:focus {
  background: $c_White;
  border-color: $primaryColor;
  box-shadow: none;
}

select.input-sm,
select.form-group-sm .form-control {
  height: auto;
}

label.error {
  color: $c_White;
  background: $warningColor;
  border: solid thin $c_White;
  padding: 3px 5px;
}

label.valid {
  color: $c_White;
  background: $successColor;
  border: solid thin $c_White;
  padding: 3px 5px;
}

//wizard
.step-content {
  border: 1px solid #d4d4d4;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.wizard {
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  *zoom: 1;
  box-shadow: 0 1px 4px rgba($c_Black, 0.065);
}

.wizard::before,
.wizard::after {
  display: table;
  line-height: 0;
  content: '';
}

.wizard::after {
  clear: both;
}

.wizard ul {
  width: 4000px;
  padding: 0;
  margin: 0;
  list-style: none outside none;
}

.wizard ul.previous-disabled li.complete {
  cursor: default;
}

.wizard ul.previous-disabled li.complete:hover {
  color: #468847;
  cursor: default;
  background: #f3f4f5;
}

.wizard ul.previous-disabled li.complete:hover .chevron::before {
  border-left-color: #f3f4f5;
}

.wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #999;
  cursor: default;
  background: #ededed;
}

.wizard ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #d4d4d4;
}

.wizard ul li .chevron::before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: '';
}

.wizard ul li.complete {
  color: $primaryColor;
  background: #f3f4f5;
}

.wizard ul li.complete:hover {
  cursor: pointer;
  background: #e1f2ef;
}

.wizard ul li.complete:hover .chevron::before {
  border-left: 14px solid #e1f2ef;
}

.wizard ul li.complete .chevron::before {
  border-left: 14px solid #f3f4f5;
}

.wizard ul li.active {
  color: $primaryColor;
  background: $c_White;
}

.wizard ul li.active .chevron::before {
  border-left: 14px solid $c_White;
}

.wizard ul li .badge {
  margin-right: 8px;
  border-radius: 30px;
}

.wizard ul li:first-child {
  padding-left: 20px;
  border-radius: 4px 0 0 4px;
}

.wizard .actions {
  position: initial;
  z-index: 1000;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: #e5e5e5;
  border-left: 1px solid #d4d4d4;
}

.wizard .actions a {
  margin-right: 8px;
  font-size: 12px;
  line-height: 45px;
}

.wizard .actions .btn-prev i {
  margin-right: 5px;
  color: $c_White;
}

.wizard .actions .btn-next i {
  margin-left: 5px;
  color: $c_White;
}

.wizard .actions .btn-prev[disabled] i {
  color: rgba($c_White, 0.75);
}

.step-content .step-pane {
  display: none;
}

.step-content .active {
  display: block;
}

.step-content .active .btn-group .active {
  display: inline-block;
}

.wizard .steps .badge.badge-info {
  background-color: $primaryColor;
}

.wizard .steps .badge.badge-success {
  background-color: $primaryColor;
}

.form-group .title {
  text-align: right;
}

//angular multi-file upload
.drop-box {
  background: $c_Seashell;
  border: 5px dashed $defaultColor;
  height: 205px;
  text-align: center;
  padding-top: 25px;
  margin-left: 20px;
}

.up-buttons {
  float: left;
}

.drop-box.dragover {
  border: 5px dashed $primaryColor;
}

.drop-box.dragover-err {
  border: 5px dashed $warningColor;
}

//angular wizard
.ng-wizard {
  margin: 10px 0;

  h2 {
    margin: 15px 0;
  }
}

.rc-nav-wizard > li {
  float: left;
  font-size: 18px;
}

.rc-nav-wizard > li + li {
  margin-left: 2px;
}

.rc-nav-wizard > li > a {
  border-radius: 5px;
  cursor: default;
  color: $defaultColor;
}

.rc-nav-wizard > li > a,
.rc-nav-wizard > li > a:hover,
.rc-nav-wizard > li > a:focus {
  background-color: transparent;
}

.rc-nav-wizard > li > a > .badge {
  margin-left: 3px;
  font-size: 18px;
  padding: 5px 9px;
  border-radius: 15px;
  color: $c_White;
  background-color: $defaultColor;
}

/* active = current wizard step */
.rc-nav-wizard > li.active > a,
.rc-nav-wizard > li.active > a:hover,
.rc-nav-wizard > li.active > a:focus {
  color: $primaryColor;
  background-color: transparent;
}

.rc-nav-wizard > .active > a > .badge {
  color: $c_White;
  background-color: $primaryColor;
}

/* success = completed wizard step */
.rc-nav-wizard > li.success > a,
.rc-nav-wizard > li.success > a:hover,
.rc-nav-wizard > li.success > a:focus {
  color: $successColor;
  background-color: transparent;
}

.rc-nav-wizard > .success > a > .badge {
  color: $c_White;
  background-color: $successColor;
}

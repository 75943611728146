/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import 'bootstrap/variables';
@import 'bootstrap/mixins';

// Reset and dependencies
@import 'bootstrap/normalize';

// Core CSS
@import 'bootstrap/scaffolding';
@import 'bootstrap/grid';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';

// Components
@import 'bootstrap/component-animations';
@import 'bootstrap/dropdowns';
@import 'bootstrap/button-groups';
@import 'bootstrap/input-groups';
@import 'bootstrap/navs';
@import 'bootstrap/navbar';
@import 'bootstrap/pagination';
@import 'bootstrap/labels';
@import 'bootstrap/badges';
@import 'bootstrap/alerts';
@import 'bootstrap/progress-bars';
@import 'bootstrap/list-group';
@import 'bootstrap/panels';
@import 'bootstrap/responsive-embed';
@import 'bootstrap/close';

// Components w/ JavaScript
@import 'bootstrap/modals';
@import 'bootstrap/tooltip';
@import 'bootstrap/popovers';

// Utility classes
@import 'bootstrap/utilities';
@import 'bootstrap/responsive-utilities';

.statistics-legend {
  display: flex;
  justify-content: center;

  span {
    margin-left: 14px;
    padding: 0px 17px;
    margin-right: 5px;
    font-size: 12px;
  }
}

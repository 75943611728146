.pageheader {
  @include clearfix;

  position: relative;
  padding: 16px 30px;
  border-bottom: 1px solid $c_Iron;
  border-top: 1px solid $c_Iron;
  background: $c_Seashell;

  .breadcrumb {
    float: left;

    .label {
      color: #999;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: normal;
      display: inline-block;
    }

    ol {
      display: inline-flex;
      background: none;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      font-size: 12px;
      color: $c_BahamaBlue;

      + li::before {
        padding: 0 2px 0 5px;
        color: $c_LimedSpruce;
        content: '|';
      }
    }

    a {
      color: $breadcrumb_link_fg;
    }

    .icon {
      width: 9px;
      height: 9px;
    }
  }

  .logged {
    float: right;
    color: $c_RollingStone;
    font-size: 12px;
  }

  .langSwitch {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $c_Iron;
    font-size: 12px;

    .dropdown-toggle {
      color: $c_RollingStone;
    }
  }
}

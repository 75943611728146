// === BOOTSTRAP Variables ===
$text-color: $c_Mako;
$primaryColor: $c_BahamaBlue;
$input-color-placeholder: $c_GrayChateau;
$link-color: $c_BahamaBlue;
$link-hover-color: shade($c_BahamaBlue, 20%);
$nav-link-hover-bg: $c_BahamaBlue;
$nav-tabs-link-hover-border-color: transparent;

// === Global ===
$body_bg: #e8e9ec;
//  @extend %stripesBg;

// === Buttons ===
$btn_primary_fg: $c_White;
$btn_primary_bg: $c_BahamaBlue;
$btn_primary_bd: $c_BahamaBlue;
$btn_primary_ac: shade($c_BahamaBlue, 10%);

$btn_secondary_fg: $c_BoschTürkis60;
$btn_secondary_bg: $c_BoschTürkis60;
$btn_secondary_bd: $c_BoschTürkis60;
$btn_secondary_ac: shade($c_BoschTürkis60, 10%);

$btn_tertiary_fg: $c_White;
$btn_tertiary_bg: $c_BoschTürkis70;
$btn_tertiary_bd: $c_BoschTürkis70;
$btn_tertiary_ac: shade($c_BoschTürkis70, 10%);

// === Breadcrumb ===
$breadcrumb_active_fg: $c_BahamaBlue;
$breadcrumb_hover_fg: $c_BahamaBlue;
$breadcrumb_link_fg: $c_BahamaBlue;

// === Header ===
$header_top_bg: linear-gradient(0deg, $c_Seashell 45%, $c_White 100%);
$header_supergraphic: '../img/supergraphic_horizontal.svg';

// === Flashmessage ===
$flashmessage_info: $c_BahamaBlue;
$flashmessage_success: $c_Lima;
$flashmessage_danger: $c_Monza;
$flashmessage_warning: $c_Monza;

// === Dropdown Dark ===
$dropdown_dark_fg: $c_White;
$dropdown_dark_bg: $c_BoschGrau10;
$dropdown_dark_ac: $c_BoschTürkis50;

// === Autocomplete token ===
$autocomplete_token_bg: $c_Mako;

// === Gallery Element ===
$galleryelement_new: linear-gradient(90deg, $c_BahamaBlue, $c_BahamaBlue);
$galleryelement_bg: $c_BahamaBlue;

// === Dropzone ===
$dropzone_fg: $c_BahamaBlue;
$dropzone_bg: tint($c_BahamaBlue, 85%);

// === Input Selected ===
$input_selected_fg: $c_BoschTürkis70;
$input_selected_bg: tint($c_BoschTürkis70, 50%);
$input_check_bg: $c_BahamaBlue;

// Icon Options
$icon_fg: $c_RollingStone;
$icon_active_fg: $c_BahamaBlue;

// === Manuel ===
$manual_hover_bg: $c_BahamaBlue;

// === Boxed whatever ===
$boxed_bg: $c_BoschTürkis70;

// === Login Page ===
$login_fg: $c_White;
$login_ln: $c_White;
$login_bg: linear-gradient(270deg, rgba($c_Black, 0) 0%, rgba($c_Black, 0.8) 100%), url('../img/login-bg-boschtt.jpg');
$login_top_bd: 0;

// === Branding ===
$branding_bd: $c_LimedSpruce_b35;
$branding_fg: $c_White;
$branding_bg: $c_BoschGrau10;

// === Brands selector ===
$brands_fg: c_White;
$brands_bg: $c_BahamaBlue;

// === Sidebar Left ===
$sidebar_left_bg: $c_BoschGrau10;

// === Sidebar Right ===
$sidebar_right_bd: $c_FrenchGray;

// === Navigation ===
$navigation_item_bd: rgba($c_Black, 0.3);
// $navigation_item_bg: $c_Mako;
$navigation_item_bg_hover: $c_BoschTürkis50;
$navigation_item_bg_active: $c_BoschTürkis50;

$navigation_item_subnav_bg: $c_BoschGrau20;
// $navigation_item_subnav_bg_hover: shade($c_BoschGrau10, 30%);
$navigation_item_subnav_bg_active: $c_BoschGrau20;

// === Gradients ===
$g_lr--primary: linear-gradient(90deg, rgba($primaryColor, 1), rgba($primaryColor, 0.75));

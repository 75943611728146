// Layout Elements
$headerHeight: 66px;
$headerInnerHeight: 60px;
$header-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
$sidebar-left-width: 250px;
$sidebar-left-mini-width: 65px;
$sidebar-right-width: 230px;
$input-height-base: 38px;

// *******************************************
// ** Customize Bootstrap, override defaults *
// *******************************************
$font-family-sans-serif: Arial, 'Helvetica Neue', Helvetica, sans-serif;
$font-size-base: 14px;
$grid-gutter-width: 10px;
// $link-hover-decoration: none;
$nav-pills-border-radius: 0;
$border-radius-base: 0;
$nav-tabs-active-link-hover-border-color: transparent;
$btn-border-radius-base: 2px;

// Forms
// $input-color-placeholder: $c_GrayChateau;

// Navigation
// $nav-link-hover-bg: $c_BahamaBlue;

// Configure Modal
$modal-md: 700px;
$modal-inner-padding: 0;
$border-radius-large: 0;
$modal-content-bg: transparent;
$modal-content-border-color: transparent;

.countrylist {
  margin-bottom: -15px;

  ul {
    display: flex;
    list-style: none;
    flex-flow: wrap;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    width: 25%;
    margin-bottom: 15px;

    img {
      margin-right: 10px;
    }
  }
}

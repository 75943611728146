@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  20% {
    transform: scale(1);
  }
}

.progress-row {
  display: block;
  float: right;
  width: 75%;
  line-height: 15px;
  height: 15px;

  .progress {
    margin: 0 10px 0 10px;
    position: relative;

    .scale {
      width: calc(100% + 6px);
      height: calc(100% - 2px);
      top: 1px;
      position: absolute;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAUCAYAAAB7wJiVAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTQ1NDA5MEE0NDY2MTFFNzk0MTdGOTQ5RjUzMUY2REQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTQ1NDA5MEI0NDY2MTFFNzk0MTdGOTQ5RjUzMUY2REQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFNDU0MDkwODQ0NjYxMUU3OTQxN0Y5NDlGNTMxRjZERCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNDU0MDkwOTQ0NjYxMUU3OTQxN0Y5NDlGNTMxRjZERCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pqtpx/oAAABRSURBVHja7NjRDQAQDEVRFfuP7NnAd8m5IzgRrZVkqEdVtadj6GUCpFlAgAgIEAH5owBxQwQEiGzqHnUB+T+/vd4QXYcsIG6I7CEPTVlHgAEAe4oJKTTSflYAAAAASUVORK5CYII=');
      background-size: 20% 100%;
      background-repeat: repeat;
    }
  }

  .part {
    display: block;
    float: left;
    width: 20%;
    text-align: right;

    &__fixed {
      display: block;
      float: left;
      width: 70%;
    }

    &__fileSize {
      display: block;
      float: left;
      width: 10%;
    }
  }
}

.totalSpaceSize {
  display: inline-flex;
}

.dropzone,
.dropzone * {
  box-sizing: border-box;
}

.dropzone {
  $image-size: 120px;
  $image-border-radius: 20px;

  min-height: 120px;
  border: 2px dashed $c_BahamaBlue;
  background: lighten($c_BahamaBlue_w15, 45%);
  padding: 20px;
  color: $c_BahamaBlue;

  &.dz-max-files-reached {
    pointer-events: none;
    cursor: default;
  }

  &.dz-clickable {
    cursor: pointer;

    * {
      cursor: default;
    }

    .dz-message {
      &,
      * {
        cursor: pointer;
      }
    }
  }

  .icon-upload {
    fill: $c_BahamaBlue;
    width: 75px;
    height: 75px;
    float: left;
    margin: 0 20px 0 0;

    & + div {
      float: left;
    }
  }

  p {
    margin: 0 0 5px 0;
  }

  a {
    text-decoration: underline;
    font-weight: 900;
  }

  .h3,
  b {
    font-weight: 900;
  }

  &.dz-started {
    .dz-message {
      display: none;
    }
  }

  &.dz-drag-hover {
    border-style: solid;

    .dz-message {
      opacity: 0.5;
    }
  }

  .dz-message {
    text-align: left;
    margin: 0;
  }

  .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;

    &:hover {
      z-index: 1000;

      .dz-details {
        opacity: 1;
      }
    }

    &.dz-file-preview {
      .dz-image {
        border-radius: $image-border-radius;
        background: #999;
        background: linear-gradient(to bottom, #eee, #ddd);
      }

      .dz-details {
        opacity: 1;
      }
    }

    &.dz-image-preview {
      background: white;

      .dz-details {
        transition: opacity 0.2s linear;
      }
    }

    .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover .dz-details {
      opacity: 1;
    }

    .dz-details {
      $background-color: #444;
      $width: 120px;

      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 20px 10px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);

      line-height: 150%;

      .dz-size {
        margin-bottom: 10px;
        font-size: 16px;
      }

      .dz-filename {
        white-space: nowrap;

        &:hover {
          span {
            border: 1px solid rgba(200, 200, 200, 0.8);
            background-color: rgba(255, 255, 255, 0.8);
          }
        }

        &:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            border: 1px solid transparent;
          }
        }
      }

      .dz-filename,
      .dz-size {
        span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 4px;
          border-radius: 3px;
        }
      }
    }

    &:hover {
      .dz-image {
        img {
          transform: scale(1.05, 1.05); // Getting rid of that white bleed-in
          filter: blur(8px); // Getting rid of that white bleed-in
        }
      }
    }

    .dz-image {
      border-radius: $image-border-radius;
      overflow: hidden;
      width: $image-size;
      height: $image-size;
      position: relative;
      display: block;
      z-index: 10;

      img {
        display: block;
      }
    }

    &.dz-success {
      .dz-success-mark {
        animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      }
    }

    &.dz-error {
      .dz-error-mark {
        opacity: 1;
        animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      }
    }

    .dz-success-mark,
    .dz-error-mark {
      $image-height: 54px;
      $image-width: 54px;

      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -($image-width / 2);
      margin-top: -($image-height / 2);

      svg {
        display: block;
        width: $image-width;
        height: $image-height;
      }
    }

    &.dz-processing .dz-progress {
      opacity: 1;

      transition: all 0.2s linear;
    }

    &.dz-complete .dz-progress {
      opacity: 0;
      transition: opacity 0.4s ease-in;
    }

    &:not(.dz-processing) {
      .dz-progress {
        animation: pulse 6s ease infinite;
      }
    }

    .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);

      // Fix for chrome bug: https://code.google.com/p/chromium/issues/detail?id=157218
      -webkit-transform: scale(1);
      border-radius: 8px;
      overflow: hidden;

      .dz-upload {
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;

        transition: width 300ms ease-in-out;
      }
    }

    &.dz-error {
      .dz-error-message {
        display: block;
      }

      &:hover .dz-error-message {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .dz-error-message {
      $width: $image-size + 20px;
      $color: rgb(190, 38, 38);

      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      display: none;
      opacity: 0;

      transition: opacity 0.3s ease;

      border-radius: 8px;
      font-size: 13px;
      top: $image-size + 10px;
      left: -10px;
      width: $width;
      background: $color;
      background: linear-gradient(to bottom, $color, darken($color, 5%));
      padding: 5px 12px;
      color: $c_White;

      // The triangle pointing up
      &::after {
        content: '';
        position: absolute;
        top: -6px;
        left: $width / 2 - 6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $color;
      }
    }
  }
}

.dz-prev {
  &.dz-success {
    .dz-success-mark {
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }

  &.dz-error {
    .dz-error-mark {
      opacity: 1;
      animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }

  .dz-success-mark,
  .dz-error-mark {
    $image-height: 54px;
    $image-width: 54px;

    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -($image-width / 2);
    margin-top: -($image-height / 2);

    svg {
      display: block;
      width: $image-width;
      height: $image-height;
    }
  }

  .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;

    background: rgba(255, 255, 255, 0.9);

    // Fix for chrome bug: https://code.google.com/p/chromium/issues/detail?id=157218
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden;

    .dz-upload {
      background: #333;
      background: linear-gradient(to bottom, $c_Lima_w60, $c_Lima);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0;
      transition: width 300ms ease-in-out;
    }
  }

  &.dz-complete {
    .dz-progress {
      opacity: 0;
      transition: opacity 400ms ease-in;
    }

    &.dz-success {
      .dz-success-mark {
        transition: opacity 400ms ease-in;
      }
    }

    &.dz-error {
      .dz-error-mark {
        opacity: 1;
        transition: opacity 400ms ease-in;
      }
    }
  }
}

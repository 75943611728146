.modal {
  color: $c_Mako;

  &.modal-large {
    .modal-dialog {
      width: 1200px;
    }
  }

  &.salesOrganisationTable {
    .modal-body {
      overflow: auto;
      max-height: 650px;
      margin-top: 30px;
    }

    th,
    td {
      max-width: 340px;
      min-width: 340px;
    }
  }
}

.modal-dialog {
  margin: 50px auto;
}

.modal-header {
  padding: 0;
  border-bottom: none;
}

.modal-content {
  box-shadow: none;

  .close {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    top: -25px;
    right: -25px;
    border-radius: 2px;
    opacity: 1;
    fill: $c_White;
  }
}

#leaveModal {
  .modal-dialog {
    margin: 50px auto;
  }

  .modal-content {
    background-color: $c_White !important;
    border-radius: 5px !important;
    padding: 20px;
    text-align: center;
    box-shadow: 0 5px 16px rgba($c_Mako, 0.5);

    p {
      margin: 0 !important;

      & + p {
        margin-top: 10px;
      }
    }
  }
}

.solid {
  .modal-dialog {
    margin: 50px auto;
  }

  .modal-content {
    background-color: $c_Seashell !important;
    border-radius: 5px !important;
    padding: 20px;
    text-align: left;
    box-shadow: 0 5px 16px rgba($c_Mako, 0.5);

    .close {
      position: static;
      border: 1px solid $c_Black;
      border-radius: 2px;
      opacity: 1;
      fill: $c_White;
    }

    .btn-cancel {
      position: absolute;
      right: 20px;
      background: transparent;
      transition: none;
      line-height: 10px;
      padding: 4px;

      .icon {
        fill: $c_Black;
      }
    }

    :last-child {
      border-bottom: none;
    }
  }

  p {
    margin-top: 0;
  }

  .modal-header {
    border: none;
    padding: 0 0 16px 0;
  }

  .modal-body {
    border-bottom: 1px solid $c_FrenchGray;
    padding: 0 0 20px 0;
  }

  .modal-footer-impostor {
    border-top: 1px solid $c_FrenchGray;
    padding: 20px 0 0 0;
    text-align: right;

    &::before {
      content: ' ';
      height: 1px;
      width: 100%;
      display: block;
      background-color: $c_White;
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }

  .modal-footer {
    border-top: 1px solid $c_White;
    padding: 10px 0 0 0;
  }
}

.fileicon {
  display: inline-block;
  width: 20px;
  height: 24px;
  background-repeat: no-repeat;
  margin-right: 10px;
  background-position: center;
  background-size: 20px auto;

  &.jpg,
  &.jpeg {
    background-image: url('../img/filetypes/small/jpg.png');
  }

  &.png {
    background-image: url('../img/filetypes/small/png.png');
  }

  &.tiff,
  &.tif {
    background-image: url('../img/filetypes/small/tif.png');
  }

  &.eps {
    background-image: url('../img/filetypes/small/eps.png');
  }

  &.indd {
    background-image: url('../img/filetypes/small/indd.png');
  }

  &.psd {
    background-image: url('../img/filetypes/small/psd.png');
  }

  &.ai {
    background-image: url('../img/filetypes/small/ai.png');
  }

  &.pdf {
    background-image: url('../img/filetypes/small/pdf.png');
  }

  &.mp4 {
    background-image: url('../img/filetypes/small/mp4.png');
  }

  &.avi {
    background-image: url('../img/filetypes/small/avi.png');
  }

  &.flv {
    background-image: url('../img/filetypes/small/flv.png');
  }

  &.xsl,
  &.xls,
  &.xlsx {
    background-image: url('../img/filetypes/small/xsl.png');
  }

  &.csv {
    background-image: url('../img/filetypes/small/csv.png');
  }

  &.other {
    background-image: url('../img/filetypes/small/file.png');
  }

  &.rar {
    background-image: url('../img/filetypes/small/rar.png');
  }

  &.zip {
    background-image: url('../img/filetypes/small/zip.png');
  }

  &.json {
    background-image: url('../img/filetypes/small/json.png');
  }

  &.xml {
    background-image: url('../img/filetypes/small/xml.png');
  }

  &.ppt {
    background-image: url('../img/filetypes/small/ppt.png');
  }

  &.xsl {
    background-image: url('../img/filetypes/small/xsl.png');
  }

  &.doc {
    background-image: url('../img/filetypes/small/doc.png');
  }

  &.docx {
    background-image: url('../img/filetypes/small/docx.png');
  }

  &.mov {
    background-image: url('../img/filetypes/small/mov.png');
  }

  &.pptx {
    background-image: url('../img/filetypes/small/pptx.png');
  }

  &.xlsx {
    background-image: url('../img/filetypes/small/xlsx.png');
  }

  &.big {
    height: 60px;
    width: 48px;
    display: block;
    margin: auto;
    background-size: 48px auto;

    &.jpg,
    &.jpeg {
      background-image: url('../img/filetypes/jpg.png');
    }

    &.png {
      background-image: url('../img/filetypes/png.png');
    }

    &.tiff,
    &.tif {
      background-image: url('../img/filetypes/tif.png');
    }

    &.eps {
      background-image: url('../img/filetypes/eps.png');
    }

    &.indd {
      background-image: url('../img/filetypes/indd.png');
    }

    &.psd {
      background-image: url('../img/filetypes/psd.png');
    }

    &.ai {
      background-image: url('../img/filetypes/ai.png');
    }

    &.pdf {
      background-image: url('../img/filetypes/pdf.png');
    }

    &.mp4 {
      background-image: url('../img/filetypes/mp4.png');
    }

    &.avi {
      background-image: url('../img/filetypes/avi.png');
    }

    &.flv {
      background-image: url('../img/filetypes/flv.png');
    }

    &.xsl,
    &.xls,
    &.xlsx {
      background-image: url('../img/filetypes/xsl.png');
    }

    &.csv {
      background-image: url('../img/filetypes/csv.png');
    }

    &.other {
      background-image: url('../img/filetypes/file.png');
    }

    &.rar {
      background-image: url('../img/filetypes/rar.png');
    }

    &.zip {
      background-image: url('../img/filetypes/zip.png');
    }

    &.json {
      background-image: url('../img/filetypes/json.png');
    }

    &.xml {
      background-image: url('../img/filetypes/xml.png');
    }

    &.ppt {
      background-image: url('../img/filetypes/ppt.png');
    }

    &.xsl {
      background-image: url('../img/filetypes/xsl.png');
    }

    &.doc {
      background-image: url('../img/filetypes/doc.png');
    }

    &.docx {
      background-image: url('../img/filetypes/docx.png');
    }

    &.mov {
      background-image: url('../img/filetypes/mov.png');
    }

    &.pptx {
      background-image: url('../img/filetypes/pptx.png');
    }

    &.xlsx {
      background-image: url('../img/filetypes/xlsx.png');
    }
  }
}

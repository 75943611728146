.fileDownload {
  display: block;
  float: left;
  color: $c_Mako;
  border: 1px solid $c_Mako;
  padding: 20px;
  cursor: pointer;
  align-items: center;
  width: 134px;
  height: 149px;
  margin: 0 16px 16px 0;
  position: relative;
  text-align: center;

  p {
    font-weight: 900;
    padding: 0;
    margin: 0;
    font-size: 9px;
  }

  &.small {
    background-color: $c_White;
    border: 3px solid transparent;
    width: 100% !important;
    height: 36px;
    padding: 3px 5px 05px;
    text-align: left !important;
    margin: 0 10px 10px 0;
    line-height: 25px;

    .fileicon {
      float: left;
    }

    .lbl {
      height: 34px !important;
      display: block;
      float: left;
      padding: 0;
    }

    span {
      margin: 0;
    }
  }

  p + p {
    font-weight: 400;
  }

  .download {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background: $c_Mako;
    width: 30px;
    height: 30px;
    text-align: center !important;

    svg {
      fill: $c_White;
      margin-top: 5px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: $c_Mako;
    border: 3px solid $c_Mako;

    .download {
      display: block;
    }

    span {
      margin-top: 0;
    }
  }

  span {
    display: inline-block;
    margin: 2px 0 21px 0;
  }

  &.small {
    span {
      margin: 0 10px 0 0;
    }
  }
}

.countrySelectDropDown {
  margin-bottom: 20px;
  width: 80%;

  .form-group {
    width: calc(100% - 25% - 5px);

    @media (max-width: 768px) {
      width: 100%;
    }

    .form-control {
      width: 100%;

      .dropdown {
        float: right;
        width: 100%;

        .caret {
          right: 0;
          position: absolute;
          top: 8px;
        }

        .dropdown-menu {
          width: 100%;
        }
      }
    }
  }

  .btn-primary {
    width: 25%;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.countrySelect-status {
  display: none;
  color: green;
  text-align: center;
}

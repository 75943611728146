// *** DEFAULT ***
@import '../../bosch/scss/colors';

$c_BoschGrau10: #1a1c1d;
$c_BoschGrau20: #2e3033;

$c_BoschTürkis50: #007bc0;
$c_BoschTürkis60: #007bc0;
$c_BoschTürkis70: #007bc0;

$c_BahamaBlue: $c_BoschTürkis50;
$c_LimedSpruce_b35: $c_BoschGrau20;

@import 'login-header';

.login {
  color: $c_Black;
  background-color: $c_Dune;
  background-image: linear-gradient(45deg, rgba($c_Black, 0) 0%, rgba($c_Black, 0.5) 100%), none;
  background-size: cover, auto;
  background-repeat: no-repeat, repeat;
  border-top: 15px solid $c_Black;
  height: 100vh;

  .main-content-wrapper {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 120px);
  }

  &__description {
    font-size: 15px;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 44px;
  }
}

.login-form {
  color: $c_White;

  @media (min-width: $screen-sm-min) {
    max-width: 50%;
  }

  .form-control::placeholder {
    color: $c_Mako;
  }

  a {
    color: $c_White;

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  &--rexroth {
    max-width: 705px;
    background-color: $c_Iron;
    color: #002b49;
    padding: 20px;
  }
}
